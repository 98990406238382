import styles from './styles.module.css';
import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { ShopContext } from '../../../Context/ShopContext';

function ShopProfile() {
  const { shop } = useContext(ShopContext);
  return (
    <>
      <div className={styles.contentLayout}>
        <div className={styles.settingsGrid}>
          <Grid container spacing={1}>
            <div style={{ marginBottom: 15 }}>
              <span className={styles.pageHead}>Shop details of {shop.name}</span>
            </div>
            <Grid item xs={6} sm={12} className={styles.BillHeader}>
              <div className={styles.fieldHead}>
                <span className={styles.fieldTitleSmall}>Shop Name</span>
              </div>
              <div className={styles.fieldInput}>
                <span className={styles.fieldTitle}>{shop.name}</span>
              </div>
            </Grid>
            <Grid item xs={6} sm={12} className={styles.BillHeader}>
              <div className={styles.fieldHead}>
                <span className={styles.fieldTitleSmall}>Tax ID</span>
              </div>
              <div className={styles.fieldInput}>
                <span className={styles.fieldTitle}>{shop.taxId || '-'}</span>
              </div>
            </Grid>
            <Grid item xs={6} sm={12} className={styles.BillHeader}>
              <div className={styles.fieldHead}>
                <span className={styles.fieldTitleSmall}>Billing Address</span>
              </div>
              <div className={styles.fieldInput}>
                <span className={styles.fieldTitle}>{shop.billingAddress || '-'}</span>
              </div>
            </Grid>

            {/* <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Subscription Rate
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    ₹ {subscription.rate}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Maximum Products
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.max_products === -1
                      ? 'Unlimited Products'
                      : `${subscription.max_products} Products`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Maximum Machines
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.max_machines === -1
                      ? 'Upto 5 Machines'
                      : `${subscription.max_machines} Machine`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Product Sync</span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.auto_sync_product_save === true
                      ? 'Automatic Product Sync to Machine '
                      : ` Manual Product Sync to Machine`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Sales Sync</span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.sales_sync_interval === -1
                      ? ' Automatic Sales Updates'
                      : ` Sales Update in ${subscription.sales_sync_interval} Hours`}
                  </span>
                </div>
              </Grid> */}
            {/* <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Subscription Validity
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {profile.subscription_end_date}
                  </span>
                </div>
              </Grid> */}
          </Grid>
        </div>
        {/* <div className={styles.Bill} style={{ placeSelf: 'flex-end' }}>
            <Grid item xs={6} sm={12} className={styles.BillHeader}>
              <div className={styles.fieldInput}>
                <span className={styles.fieldTitleSmall}>
                  Subscription Plans
                </span>
              </div>
              <div className={styles.fieldInput}>
                <Link
                  to="/subscription-plans"
                  style={{ textDecorationColor: 'transparent' }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.saveBtnPressed}
                    disabled={this.state.loadingIndicator}
                  >
                    purchase Plan
                  </Button>
                </Link>
              </div>
            </Grid>
          </div> */}
      </div>
    </>
  );
}

export default ShopProfile;

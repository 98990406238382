import { Button, Grid, TextField, Checkbox } from '@material-ui/core';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState, useContext } from 'react';
import CustomerService from '../../services/CustomerService';
import { DialogActions } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';
import { ThemeContext } from '../../Context/ThemeContext';
import { STATUS } from '../../const';

export default function EditCustomerDialog(props) {
  const { toggleEditTab, editData, getCustomers } = props;
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { theme } = useContext(ThemeContext);

  const [name, setName] = useState(editData.name);
  const [mobile, setMobile] = useState(editData.mobile);
  const [location, setLocation] = useState(editData.location);
  const [status, setStatus] = useState(editData.status === STATUS.ACTIVE ? true : false);
  const [creditLimit, setCreditLimit] = useState(editData.creditLimit);
  const [trn, setTrn] = useState(editData?.trnNumber);
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const handleUpdateCustomer = async () => {
    setLoadingIndicator(true);
    try {
      const data = {
        ...editData,
        name,
        mobile,
        location,
        status: status ? STATUS.ACTIVE : STATUS.DE_ACTIVE,
        creditLimit: Number(creditLimit),
        trnNumber: trn ? Number(trn) : null,
      };
      await CustomerService.updateCustomer(editData.id, data);
      postAlertMessage({
        text: 'Customer updated successfully',
        type: 'success',
      });
      getCustomers();
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
    toggleEditTab();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Edit Customer</h2>
          <CloseIcon onClick={toggleEditTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Customer ID</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField label="Customer Id" variant="outlined" size="small" fullWidth disabled value={editData.id} />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Customer Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Mobile</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Mobile"
                name="Mobile"
                size="small"
                variant="outlined"
                fullWidth
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Credit Limit</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Credit Limit"
                variant="outlined"
                size="small"
                fullWidth
                value={creditLimit}
                onChange={(e) => {
                  setCreditLimit(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Place</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Place"
                name="location"
                size="small"
                variant="outlined"
                fullWidth
                value={location}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>{theme?.variable?.trn_label}</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label={theme?.variable?.trn_label}
                name="location"
                size="small"
                variant="outlined"
                fullWidth
                value={trn}
                onChange={(e) => {
                  setTrn(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Active</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <Checkbox
                color="primary"
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button variant="contained" color="secondary" size="small" onClick={toggleEditTab}>
              close
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={name === '' || mobile === '' || location === '' || loadingIndicator}
              onClick={handleUpdateCustomer}
            >
              Update Customer
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable/';
import NewCustomerDialog from '../../popups/NewCustomerDialog';
import EditCustomerDialog from '../../popups/EditCustomerDialog';
import CustomerService from '../../../services/CustomerService';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { ShopContext } from '../../../Context/ShopContext';
import { ROUTES, STATUS } from '../../../const';
import { useHistory } from 'react-router-dom';
import { Paper, TablePagination, Typography } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';
import ShopsHelper from '../../../helpers/ShopsHelper';

function Customers() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const history = useHistory();

  const [customers, setCustomers] = useState();
  const [filterText, setFilterText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [addTab, setAddTab] = useState('');
  const [editData, setEditData] = useState('');
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [isEnableSearchRefreshButton, setIsEnableSearchRefreshButton] = useState(false);

  const toggleEditTab = (rowData = '') => {
    editData ? setEditData('') : setEditData(rowData);
  };

  const toggleAddTab = (move = '') => {
    if (move === 'save') getCustomers();
    addTab ? setAddTab('') : setAddTab('open');
  };

  const getCustomers = async ({ _offset = offset, _limit = limit } = {}) => {
    setLoadingIndicator(true);
    try {
      const res = await CustomerService.getCustomers({
        offset: _offset,
        limit: _limit,
        search: filterText,
      });
      setCustomers(res);
      setIsEnableSearchRefreshButton(false);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setDelay(setLoadingIndicator);
  };

  const redirectToCustomerrDetails = async (data) => {
    history.push(`${ROUTES.CUSTOMERS}/${data.id}`);
  };

  const getBalance = (data) => {
    const balance = ShopsHelper.getAmountFormatted(shop, data?.balance);
    let balanceStyle;
    if (balance >= 0) balanceStyle = { color: 'green' };
    else if (balance > data?.creditLimit) balanceStyle = { color: 'orange' };
    else balanceStyle = { color: 'red' };
    return (
      <Typography variant="body2" style={balanceStyle} gutterBottom>
        {balance}
      </Typography>
    );
  };

  const headerData = [
    {
      label: 'ID',
      id: 'code',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Mobile',
      id: 'mobile',
      type: 'text',
    },
    {
      label: 'Place',
      id: 'location',
      type: 'text',
    },
    {
      label: 'Credit Limit',
      id: 'creditLimit',
      type: 'text',
    },
    {
      label: 'TRN',
      id: 'trnNumber',
      type: 'text',
    },
    {
      label: 'Active',
      id: 'status',
      type: 'callback',
      viewRender: (item) => {
        return item.status === STATUS.ACTIVE ? 'Yes' : 'No';
      },
    },
    {
      label: 'Balance',
      id: 'balance',
      type: 'callback',
      viewRender: (data) => getBalance(data),
    },
    {
      label: 'Purchase Details',
      id: 'purchaseDetails',
      type: 'button',
      title: 'Details',
      clickHandler: redirectToCustomerrDetails,
    },
    {
      label: 'Edit',
      id: 'updateItems',
      type: 'button',
      title: 'Edit',
      clickHandler: toggleEditTab,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let offset = newPage * limit;
    setOffset(offset);
    getCustomers({ _offset: offset });
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    getCustomers({ _limit: limit });
  };

  useEffect(() => {
    getCustomers();
    //eslint-disable-next-line
  }, [shop?.id]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Customer<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <h2 className={styles.subTitle}>Customers</h2>
          <div className={styles.filerInputSec}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ backgroundColor: '#ff851b' }}
              className={styles.actionBtn}
              onClick={toggleAddTab}
            >
              <NoteAddIcon className={styles.actionBtnIcon} />
              New Customer
            </Button>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={filterText}
                onChange={(e) => {
                  setFilterText(e.target.value);
                  setIsEnableSearchRefreshButton(true);
                }}
                className={styles.searchInput}
                placeholder="search items"
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={() => {
                  setOffset(0);
                  setPage(0);
                  getCustomers({ _offset: 0 });
                }}
                disabled={!isEnableSearchRefreshButton}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>

      {customers && customers.length ? (
        <Paper className={styles.tableWrapper}>
          <DataTable columns={headerData} rows={customers} toggleEditTab={toggleEditTab} deactiveRow={true} />
          <TablePagination
            rowsPerPageOptions={[20, 50]}
            component="div"
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={() => ''}
          />
        </Paper>
      ) : (
        !loadingIndicator && (
          <Info
            title={'No customers to list'}
            content={
              'You have no customers to list with current filter configuration. Please clear the filters or create a customer'
            }
          />
        )
      )}
      {editData && <EditCustomerDialog toggleEditTab={toggleEditTab} editData={editData} getCustomers={getCustomers} />}
      {addTab && <NewCustomerDialog toggleAddTab={toggleAddTab} />}
    </div>
  );
}

export default withConsoleBase(Customers);

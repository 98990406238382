import { Button, CardMedia, Checkbox, FormControlLabel, MenuItem, TextField } from '@material-ui/core';
import { DialogActions, Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import ItemService from '../../services/ItemService';
import Loader from '../utils/Loading';
import { AlertMessagesContext } from 'react-alert-messages';
import { STATUS } from '../../const';

export default function EditItemDialog(props) {
  const { handleClose, item, categories, onSuccess } = props;
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [data, setData] = useState(item);
  const [imageData, setImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const readImageFile = (imageFile) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onloadend = async () => {
        resolve(reader.result);
      };
    });
  };

  const hasAnyUpdate = () => {
    return Object.keys(getRequestDataToUpdate()).length > 0 || imageData;
  };

  const getRequestDataToUpdate = () => {
    const requestData = { rent: {} };
    if (String(item.categoryId) !== String(data.categoryId)) {
      requestData.categoryId = data.categoryId;
    }
    if (String(item.name) !== String(data.name)) {
      requestData.name = data.name;
    }
    if (String(item.unit) !== String(data.unit)) {
      requestData.unit = data.unit;
    }
    if (String(item.tax) !== String(data.tax)) {
      requestData.tax = data.tax;
    }
    if (String(item.rent.hourly) !== String(data.rent.hourly)) {
      requestData.rent.hourly = data.rent.hourly;
    }
    if (String(item.rent.daily) !== String(data.rent.daily)) {
      requestData.rent.daily = data.rent.daily;
    }
    if (String(item.rent.monthly) !== String(data.rent.monthly)) {
      requestData.rent.daily = data.rent.daily;
    }
    if (String(item.rent.yearly) !== String(data.rent.yearly)) {
      requestData.rent.yearly = data.rent.yearly;
    }
    if (String(item.rent.oneTime) !== String(data.rent.oneTime)) {
      requestData.rent.oneTime = data.rent.oneTime;
    }
    if (String(item.stock) !== String(data.stock)) {
      requestData.stock = data.stock ? Number(data.stock) : 0;
    }
    if (String(item.stockInMaintenance) !== String(data.stockInMaintenance)) {
      requestData.stockInMaintenance = data.stockInMaintenance || 0;
    }
    if (String(item.status) !== String(data.status)) {
      requestData.status = data.status;
    }
    if (String(item.order) !== String(data.order)) {
      requestData.order = data.order;
    }
    return requestData;
  };

  const handleUpdateItem = async () => {
    setIsLoading(true);
    try {
      await ItemService.updateItem(item.id, data);

      if (imageData) {
        const data = { image: await readImageFile(imageData) };
        await ItemService.uploadItemImage(data, item.id);
      }
      postAlertMessage({
        text: 'Item updated successfully',
        type: 'success',
      });
      onSuccess();
      handleClose();
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setIsLoading(false);
  };

  const onEditItemButtonClicked = async () => {
    await handleUpdateItem();
  };

  return (
    <>
      <Loader isOpen={isLoading} />
      <div className={styles.popUp}>
        <div className={styles.contentWrapper}>
          <div className={styles.headSec}>
            <h2 className={styles.editTitle}>Edit item</h2>
            <CloseIcon onClick={handleClose} />
          </div>
          <div className={styles.inputSec}>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Item ID</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField label="Item Id" variant="outlined" size="small" fullWidth disabled value={item.id} />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Item Name</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={data.name}
                  onChange={(e) => {
                    setData({ ...data, name: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>category</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  select
                  size="small"
                  variant="outlined"
                  color="primary"
                  value={data.categoryId}
                  onChange={(e) => {
                    setData({ ...data, categoryId: e.target.value });
                  }}
                  fullWidth
                >
                  {categories.map((category) => (
                    <MenuItem value={category.id}>{category.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Item Image</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <div className={styles.imageContainer}>
                  <CardMedia image={imageData ? URL.createObjectURL(imageData) : item.image} className={styles.image} />
                </div>
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  id="upload"
                  onChange={(e) => {
                    const img = e.target.files[0];
                    if (img.size > 101000) {
                      postAlertMessage({
                        text: 'Upload file size should be less than 100KB.',
                        type: 'failed',
                      });
                      return;
                    }
                    setImageData(img);
                  }}
                />
                <Button variant="contained" className={styles.uploadBtn}>
                  <label for="upload">Select</label>
                </Button>
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Unit</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  select
                  name="unit"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={data.unit}
                  onChange={(e) => {
                    setData({ ...data, unit: e.target.value });
                  }}
                >
                  <MenuItem value="Kg">Kg</MenuItem>
                  <MenuItem value="Pcs">Pcs</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Stock</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Stock"
                  variant="outlined"
                  size="small"
                  type="number"
                  fullWidth
                  value={data.stock}
                  onChange={(e) => {
                    const val = e.target.value;
                    setData({ ...data, stock: val ? Number(val) : null });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Maintenance</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Maintenance"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={data.stockInMaintenance}
                  onChange={(e) => {
                    const val = e.target.value;
                    setData({ ...data, stockInMaintenance: val ? Number(val) : null });
                  }}
                />
              </Grid>
            </Grid>

            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Order</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Order"
                  variant="outlined"
                  size="small"
                  type="number"
                  className={styles.numberInput}
                  name="order"
                  value={data.order}
                  onChange={(e) => {
                    const val = e.target.value;
                    setData({ ...data, order: val ? Number(val) : null });
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Tax</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Tax"
                  variant="outlined"
                  size="small"
                  type="number"
                  className={styles.numberInput}
                  name="order"
                  value={data.tax}
                  onChange={(e) => {
                    const val = e.target.value;
                    setData({ ...data, tax: val ? Number(val) : null });
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Hourly</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Hourly"
                  variant="outlined"
                  size="small"
                  type="numebr"
                  fullWidth
                  value={data.rent.hourly}
                  onChange={(e) => {
                    const val = e.target.value;
                    setData({ ...data, rent: { ...data.rent, hourly: val ? Number(val) : null } });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Daily</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Daily"
                  variant="outlined"
                  size="small"
                  type="numebr"
                  fullWidth
                  value={data.rent.daily}
                  onChange={(e) => {
                    const val = e.target.value;
                    setData({ ...data, rent: { ...data.rent, daily: val ? Number(val) : null } });
                  }}
                />
              </Grid>
            </Grid>

            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Monthly</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Monthly"
                  variant="outlined"
                  size="small"
                  type="numebr"
                  fullWidth
                  value={data.rent.monthly}
                  onChange={(e) => {
                    const val = e.target.value;
                    setData({ ...data, rent: { ...data.rent, monthly: val ? Number(val) : null } });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Yearly</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Yearly"
                  variant="outlined"
                  size="small"
                  type="numebr"
                  fullWidth
                  value={data.rent.yearly}
                  onChange={(e) => {
                    const val = e.target.value;
                    setData({ ...data, rent: { ...data.rent, yearly: val ? Number(val) : null } });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>One Time</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="One Time"
                  variant="outlined"
                  size="small"
                  type="numebr"
                  fullWidth
                  value={data.rent.oneTime}
                  onChange={(e) => {
                    const val = e.target.value;
                    setData({ ...data, rent: { ...data.rent, oneTime: val ? Number(val) : null } });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="flex-start">
              <Grid item xs={4} />
              <Grid item xs={4}>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={data.status === STATUS.ACTIVE ? true : false}
                      onChange={() =>
                        setData({
                          ...data,
                          status: data.status === STATUS.ACTIVE ? STATUS.DE_ACTIVE : STATUS.ACTIVE,
                        })
                      }
                    />
                  }
                  label="Available for sale"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </div>
          <div className={styles.footSec}>
            <DialogActions>
              <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                close
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={data.name === '' || data.stock === '' || !hasAnyUpdate()}
                onClick={onEditItemButtonClicked}
              >
                Update Item
              </Button>
            </DialogActions>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useCallback, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { Grid, Tooltip, Divider, Button } from '@mui/material';
import styles from './styles.module.css';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { AlertMessagesContext } from 'react-alert-messages';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { useParams, Link, useHistory } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../utils/Loading';
import CustomMenuItem from '../../utils/CustomComponents/CustomMenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomerServices from '../../../services/CustomerService';
import SaleService from '../../../services/SaleService';
import NewCustomerDialog from '../../popups/NewCustomerDialog';
import { ShopContext } from '../../../Context/ShopContext';
import { RATE_DURATION, ROUTES, SALE_STATUS } from '../../../const';
import { ProductContext } from '../../../Context/ProductContext';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import WarningDialog from '../../popups/WarningDialog';

const durationKeyMap = {
  hourly: 'hours',
  daily: 'days',
  monthly: 'months',
  yearly: 'years',
  oneTime: 'oneTime',
};

function Dashboard() {
  const { shop } = useContext(ShopContext);
  const history = useHistory();
  const { saleId } = useParams();
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { items, isLoading: isLoadingProductContext } = useContext(ProductContext);

  const [saleData, setSaleData] = useState({});
  const [customerId, setCustomerId] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(null);
  const [item, setItem] = useState(null);
  const [itemName, setItemName] = useState('');
  const [rate, setRate] = useState('');
  const [rateDuration, setRateDuration] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [itemTotalAmount, setItemTotalAmount] = useState('');
  const [saleItems, setSaleItems] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [isShowAddCustomerDialog, setShowAddCustomerDialog] = useState(false);
  const [itemCode, setItemCode] = useState(0);
  const [paidAmount, setPaidAmount] = useState('');
  const [discount, setDiscount] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState('');
  const [taxAmount, setTaxAmount] = useState(0);
  const [itemTotalRate, setItemTotalRate] = useState(0);
  const [saleTotals, setSaleTotals] = useState({});
  const [billDate, setBillDate] = useState(null);
  const [activeSales, setActiveSales] = useState([]);
  const [customerNotSelected, setCustomerNotSelected] = useState(false);
  const [itemRentalPeriod, setItemRentalPeriod] = useState({});

  const openCreateCustomerDialog = () => {
    setShowAddCustomerDialog(true);
  };
  const toggleCreateCustomerDialog = (action) => {
    if (action === 'save') {
      getCustomers();
    }
    setShowAddCustomerDialog(!isShowAddCustomerDialog);
  };

  const getCustomers = async () => {
    const resp = await CustomerServices.getCustomers();
    setCustomers(resp);
  };

  const getActiveSales = async () => {
    const resp = await SaleService.getSales({ active: true, offset: 0, limit: 8 });
    setActiveSales(resp);
  };

  const fetchInitialData = useCallback(async () => {
    setLoadingIndicator(true);
    await Promise.all([getCustomers(), getActiveSales()]);
    setLoadingIndicator(false);
  }, []);

  useEffect(() => {
    if (shop) {
      fetchInitialData();
    }
  }, [fetchInitialData, shop]);

  useEffect(() => {
    if (!saleId) {
      return;
    }

    const getSaleData = async () => {
      const sale = await SaleService.getSale(saleId);
      setSaleData(sale);
      setDiscount(sale.discount);
      setReturnDate(sale.returnDate ? new Date(sale.returnDate) : null);
      setStartDate(new Date(sale.startDate));
      setSaleItems(sale.items);
      setCustomerId(sale.customerId || '');
      setPaidAmount(sale.paidAmount);
      setAdvanceAmount(sale.advanceAmount);
      setBillDate(sale.billDate ? new Date(sale.billDate) : null);
      setSaleTotals({ totalTax: sale.totalTax, totalAmount: sale.totalAmount });
    };
    getSaleData();
  }, [saleId]);

  const handleClearPurchase = () => {
    if (saleId) {
      const newUrl = ROUTES.DASHBOARD;
      history.replace(newUrl);
      return;
    }
    setSaleItems([]);
    setItem(null);
    setPaidAmount('');
    setAdvanceAmount('');
    setCustomerId('');
    resetItemFields();
    setStartDate(new Date());
    setReturnDate(null);
    setBillDate(null);
    setDiscount(0);
    setSaleTotals({});
    setSaleData({});
  };

  const resetItemFields = () => {
    setItemName('');
    setRate('');
    setQuantity(1);
    setTaxAmount('');
    setItemTotalRate('');
    setItem('');
    setItemCode('');
    setRateDuration('');
    setItemTotalAmount('');
    setItemRentalPeriod({});
  };

  const addItem = () => {
    let data = {
      code: Number(itemCode),
      quantity: Number(quantity),
      taxAmount: Number(taxAmount),
      rateDuration,
      rentalPeriod: itemRentalPeriod,
      itemTotalRate: Number(itemTotalRate),
      rate: rate ? Number(rate) : null,
      itemId: item.id,
      total: Number(itemTotalAmount),
    };
    const updatedSaleItems = [...saleItems, data];
    setSaleItems(updatedSaleItems);

    resetItemFields();

    updateSaleTotals(updatedSaleItems);
  };

  const updateSaleTotals = (items) => {
    const totals = items.reduce(
      (acc, item) => {
        acc.totalTax += item.taxAmount || 0;
        acc.totalItemAmount += item.itemTotalRate || 0;
        acc.totalAmount += item.total || 0;
        acc.totalQuantity += item.quantity || 0;
        return acc;
      },
      { totalTax: 0, totalAmount: 0, totalItemAmount: 0, totalQuantity: 0 }
    );

    setSaleTotals({
      totalTax: totals.totalTax,
      totalAmount: totals.totalAmount,
      totalQuantity: totals.totalQuantity,
      totalItemAmount: totals.totalItemAmount,
    });
  };

  const handleAddSale = async () => {
    if (!customerId) {
      setCustomerNotSelected(true);
      return;
    }
    setLoadingIndicator(true);
    try {
      const saledata = {
        returnDate,
        discount,
        startDate,
        items: saleItems,
        advanceAmount: Number(advanceAmount),
        paidAmount: Number(paidAmount),
        totalTax: saleTotals.totalTax,
        totalAmount: saleTotals.totalAmount,
        customerId,
        billDate,
      };
      const resp = await SaleService.createSale(saledata);
      setSaleData({ ...resp, ...saledata, status: SALE_STATUS.DRAFT });
      getActiveSales();
      postAlertMessage({
        text: 'Sale Added successfully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const updateSaleStatus = async (updateData = {}) => {
    setLoadingIndicator(true);
    try {
      await SaleService.updateSalePartally(saleData?.id, updateData);
      postAlertMessage({
        text: 'Sale updated successfully',
        type: 'success',
      });
      setSaleData({ ...saleData, ...updateData });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const dltItem = (item) => {
    const updatedSaleItems = saleItems.filter((itm) => itm.code !== item.code || itm.total !== item.total);
    setSaleItems(updatedSaleItems);
    updateSaleTotals(updatedSaleItems);
  };

  const handleEditItem = (saleItem) => {
    const editItem = items.find((itm) => itm.code === saleItem.code);
    setItem(editItem);
    setItemCode(saleItem?.code);
    setItemName(items.find((itm) => itm.id === saleItem.itemId).name);
    setQuantity(saleItem?.quantity);
    setRate(saleItem?.rate || 0);
    setRateDuration(saleItem.rateDuration || '');
    setItemRentalPeriod(saleItem?.rentalPeriod);
    setTaxAmount(saleItem?.taxAmount || 0);
    const filteredSaleItems = saleItems.filter((itm) => itm.code !== saleItem.code || itm.total !== saleItem.total);
    setSaleItems(filteredSaleItems);
    updateSaleTotals(filteredSaleItems);
  };

  const handleEditSale = async () => {
    setLoadingIndicator(true);
    try {
      const data = {
        ...saleData,
        returnDate,
        discount,
        startDate,
        items: saleItems,
        advanceAmount: Number(advanceAmount),
        paidAmount: Number(paidAmount),
        totalTax: saleTotals.totalTax,
        totalAmount: saleTotals.totalAmount,
        customerId,
        billDate,
      };
      await SaleService.updateSale(saleId, data);
      postAlertMessage({
        text: 'Sale updated successfully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const getItemRate = (item) => {
    if (!item) return;
    const { hourly, daily, monthly, yearly, oneTime } = item?.rent;

    return [
      { value: hourly, label: '/h', key: 'hourly' },
      { value: daily, label: '/d', key: 'daily' },
      { value: monthly, label: '/m', key: 'monthly' },
      { value: yearly, label: '/y', key: 'yearly' },
      { value: oneTime, label: '/ot', key: 'oneTime' },
    ].filter((rate) => rate.value);
  };

  const getTaxAmount = useCallback(
    (item, total = itemTotalRate) => {
      const tax = item.tax;
      if (!tax || tax === 0) return 0;
      return (tax * (total * quantity)) / 100;
    },
    [itemTotalRate, quantity]
  );

  const getItemTotalRate = useCallback(
    (item, saleItem = {}) => {
      const { hourly, daily, monthly, yearly, oneTime } = item.rent;
      const diffMs = (returnDate ? returnDate : DateTimeHelpers.getOneDay(new Date())) - new Date(startDate);

      const totalMinutes = diffMs / (1000 * 60);
      const totalHours = diffMs / (1000 * 60 * 60);
      const totalDays = diffMs / (1000 * 60 * 60 * 24);

      const hours = Math.floor(totalHours) + (totalMinutes % 60 > 15 ? 1 : 0);
      const days = Math.floor(totalDays) + (totalHours % 24 > 1 ? 1 : 0);
      const months = Math.ceil(days / 28);
      const years = Math.ceil(days / 365);

      let totalValue = 0;
      let rentalPeriod = {};
      const durationValues = { hourly: hours, daily: days, monthly: months, yearly: years, oneTime: 1 };
      const selectedRateDuration = rateDuration || saleItem?.rateDuration;
      const selectedRate = rate || saleItem?.rate;
      if (selectedRateDuration) {
        const durationKey = durationKeyMap[selectedRateDuration];
        rentalPeriod[durationKey] = durationValues[selectedRateDuration];

        totalValue = selectedRate * (durationValues[selectedRateDuration] || 1);
      } else {
        if (hourly) rentalPeriod.hours = hours;
        if (daily) rentalPeriod.days = days;
        if (monthly) rentalPeriod.months = months;
        if (yearly) rentalPeriod.years = years;
        if (oneTime) rentalPeriod.oneTime = 0;

        if (hourly) totalValue += hourly * hours;
        if (daily) totalValue += daily * days;
        if (monthly) totalValue += monthly * months;
        if (yearly) totalValue += yearly * years;
        if (oneTime) totalValue += oneTime;
      }

      return { totalValue, rentalPeriod };
    },
    [returnDate, rate, startDate, rateDuration]
  );

  const addItemPurchaseValues = (item) => {
    setItem(item || '');
    setItemName(item.name || '');
    const rates = getItemRate(item);
    if (rates.length > 1) {
      setRate(null);
      setRateDuration(null);
    } else {
      setRate(rates[0]?.value || 0);
      setRateDuration(rates[0]?.key || '');
    }
    const { totalValue: itemToatalAmount, rentalPeriod } = getItemTotalRate(item);
    setItemRentalPeriod(rentalPeriod);
    setItemTotalRate(itemToatalAmount);
    setTaxAmount(getTaxAmount(item, itemToatalAmount) || 0);
    setItemCode(item.code);
  };

  useEffect(() => {
    if (saleItems.length < 1) return;
    const updatedSaleItems = saleItems.map((si) => {
      const item = items.find((i) => i.id === si.itemId);
      const { totalValue: itemRate, rentalPeriod } = getItemTotalRate(item, si);
      const tax = getTaxAmount(item, itemRate);
      return {
        ...si,
        taxAmount: tax,
        itemTotalRate: itemRate,
        total: si.quantity * itemRate + tax,
        rentalPeriod,
      };
    });
    setSaleItems(updatedSaleItems);
    updateSaleTotals(updatedSaleItems);
    //eslint-disable-next-line
  }, [startDate, returnDate]);

  useEffect(() => {
    if (!item) return;
    const { totalValue: itemTotalRate, rentalPeriod } = getItemTotalRate(item);
    const itemTaxAmount = getTaxAmount(item, itemTotalRate);
    setItemTotalRate(itemTotalRate);
    setItemRentalPeriod(rentalPeriod);
    setTaxAmount(itemTaxAmount);
    setItemTotalAmount(Number(itemTotalRate) * Number(quantity) + Number(itemTaxAmount));
  }, [itemTotalRate, quantity, taxAmount, item, getItemTotalRate, getTaxAmount]);

  return (
    <Grid container className={styles.contentWrapper} columnSpacing={1}>
      <Loader isOpen={loadingIndicator || isLoadingProductContext} />
      <Grid item xs={12} className={styles.titleSec}>
        <h2 className={styles.title}>
          Sales<span className={styles.menuTitle}>Dashboard</span>
        </h2>
      </Grid>
      <Grid item container xs={12} md={10} className={styles.changeable} rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12} className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>New Sale</h2>
          </div>
        </Grid>
        <Grid
          item
          xs={2}
          sm={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={styles.inputLabelContainer}
        >
          <h3 className={styles.inputLabel}>Customer</h3>
        </Grid>
        <Grid item xs={4} sm={3}>
          <TextField
            select
            labelId="demo-select-small-label"
            fullWidth
            id="demo-select-small"
            value={customerId}
            label="Customer"
            variant="outlined"
            size="small"
            onChange={(e) => {
              setCustomerId(e.target.value);
            }}
          >
            {(customers || []).map((customer) => (
              <MenuItem key={customer?.id} value={customer?.id}>
                {customer?.name}
              </MenuItem>
            ))}
            <CustomMenuItem onClick={openCreateCustomerDialog}>
              <AddCircleIcon fontSize="large" style={{ marginLeft: 5 }} />{' '}
              <p className={styles.normalText}>Create New Customer</p>
            </CustomMenuItem>
          </TextField>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={styles.inputLabelContainer}
        >
          <h3 className={styles.inputLabel}>Start Date</h3>
        </Grid>
        <Grid item xs={4} sm={4} style={{ display: 'flex' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableFuture
              format="dd/MM/yyyy"
              id="date-picker-from"
              className={styles.dateBox}
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'Change date',
              }}
            />
            <KeyboardTimePicker
              disableFuture
              id="time-picker-from"
              className={styles.dateBox}
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'Change Time',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          item
          xs={2}
          sm={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={styles.inputLabelContainer}
        >
          <h3 className={styles.inputLabel}>Advance Amount</h3>
        </Grid>
        <Grid item xs={4} sm={3}>
          <TextField
            label="Advance Amount"
            variant="outlined"
            size="small"
            fullWidth
            value={advanceAmount}
            onChange={(e) => {
              const val = Number(e.target.value);
              setAdvanceAmount(val);
              setPaidAmount(val);
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          spacing={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={styles.inputLabelContainer}
        >
          <h3 className={styles.inputLabel}>Return Date</h3>
        </Grid>
        <Grid item xs={4} sm={4} style={{ display: 'flex' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              id="date-picker-from"
              className={styles.dateBox}
              value={returnDate}
              onChange={(date) => {
                setReturnDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'Change date',
              }}
            />
            <KeyboardTimePicker
              id="time-picker-from"
              className={styles.dateBox}
              value={returnDate}
              onChange={(date) => {
                setReturnDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'Change Time',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          item
          xs={2}
          sm={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={styles.inputLabelContainer}
        >
          <h3 className={styles.inputLabel}>Paid Amount</h3>
        </Grid>
        <Grid item xs={4} sm={3}>
          <TextField
            label="Paid Amount"
            variant="outlined"
            size="small"
            fullWidth
            value={paidAmount}
            onChange={(e) => {
              setPaidAmount(Number(e.target.value));
            }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          spacing={2}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={styles.inputLabelContainer}
        >
          <h3 className={styles.inputLabel}>Bill Date</h3>
        </Grid>
        <Grid item xs={4} sm={4} style={{ display: 'flex' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              id="date-picker-from"
              className={styles.dateBox}
              value={billDate}
              onChange={(date) => {
                setBillDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'Change date',
              }}
            />
            <KeyboardTimePicker
              id="time-picker-from"
              className={styles.dateBox}
              value={billDate}
              onChange={(date) => {
                setBillDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'Change Time',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          item
          xs={2}
          sm={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={styles.inputLabelContainer}
        >
          <h3 className={styles.inputLabel}>Discount</h3>
        </Grid>
        <Grid item xs={4} sm={3}>
          <TextField
            label="Discount"
            variant="outlined"
            size="small"
            fullWidth
            value={discount}
            onChange={(e) => {
              setDiscount(Number(e.target.value));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer style={{ marginTop: 4 }} component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.columnCell}>Code</TableCell>
                  <TableCell className={styles.nameColumn}>Name</TableCell>
                  <TableCell className={styles.columnCell} style={{ width: '150px' }}>
                    Rate
                  </TableCell>
                  <TableCell className={styles.columnCell} style={{ width: '100px' }}>
                    {saleItems.every(
                      (item) =>
                        item.rentalPeriod?.days &&
                        !item.rentalPeriod?.hours &&
                        !item.rentalPeriod?.months &&
                        !item.rentalPeriod?.years &&
                        !item.rentalPeriod?.oneTime
                    )
                      ? 'Days'
                      : 'Duration'}
                  </TableCell>
                  <TableCell className={styles.columnCell} style={{ width: '80px' }}>
                    Count
                  </TableCell>
                  <TableCell className={styles.columnCell} style={{ width: '80px' }}>
                    Total
                  </TableCell>
                  <TableCell className={styles.columnCell} style={{ width: '100px' }}>
                    Total Tax
                  </TableCell>
                  <TableCell className={styles.columnCell}>Payable</TableCell>
                  <TableCell style={{ width: '40px' }}></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {saleItems &&
                  saleItems.map((saleItem) => (
                    <TableRow key={saleItem.id}>
                      <TableCell>{saleItem.code}</TableCell>
                      <TableCell>{items.find((itm) => itm.id === saleItem.itemId).name}</TableCell>
                      <TableCell>
                        {saleItem.rate
                          ? `${saleItem.rate} /${saleItem.rateDuration.charAt(0)}`
                          : getItemRate(items.find((itm) => itm.id === saleItem.itemId))
                              .map((rate) => `${rate.value}${rate.label}`)
                              .join(' + ')}
                      </TableCell>
                      <TableCell>
                        {saleItem.rentalPeriod?.days &&
                        !saleItem.rentalPeriod?.hours &&
                        !saleItem.rentalPeriod?.months &&
                        !saleItem.rentalPeriod?.years &&
                        !saleItem.rentalPeriod?.oneTime
                          ? `${saleItem.rentalPeriod?.days}d`
                          : `${saleItem.rentalPeriod?.hours ? saleItem.rentalPeriod?.hours + 'h/' : ''}${
                              saleItem.rentalPeriod?.days ? saleItem.rentalPeriod?.days + 'd' : ''
                            }${saleItem.rentalPeriod?.months ? '/' + saleItem.rentalPeriod?.months + 'm' : ''}${
                              saleItem.rentalPeriod?.years ? '/' + saleItem.rentalPeriod?.years + 'y' : ''
                            }${saleItem.rentalPeriod?.oneTime ? '-' : ''}`}
                      </TableCell>
                      <TableCell>{saleItem.quantity}</TableCell>
                      <TableCell>{saleItem.itemTotalRate}</TableCell>
                      <TableCell>{saleItem.taxAmount}</TableCell>
                      <TableCell>
                        {Number(saleItem.itemTotalRate) * Number(saleItem.quantity) + Number(saleItem.taxAmount)}
                      </TableCell>
                      <TableCell>
                        <DeleteIcon onClick={() => dltItem(saleItem)} />
                      </TableCell>
                      <TableCell>
                        <EditIcon onClick={() => handleEditItem(saleItem)} />
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow key="input row">
                  <TableCell>
                    <Autocomplete
                      value={item?.code}
                      onChange={(event, newItem) => {
                        addItemPurchaseValues(newItem);
                      }}
                      disableClearable
                      inputValue={String(itemCode)}
                      onInputChange={(e, value) => {
                        setItemCode(value || '');
                      }}
                      getOptionLabel={(option) => (option?.code ? String(option.code) : '')}
                      options={items}
                      renderInput={(params) => (
                        <TextField {...params} label="Code" variant="outlined" size="small" fullWidth />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Autocomplete
                      value={item?.name}
                      onChange={(event, newItem) => {
                        addItemPurchaseValues(newItem);
                      }}
                      disableClearable
                      inputValue={itemName}
                      onInputChange={(e, value) => {
                        setItemName(value);
                      }}
                      getOptionLabel={(option) => `${option?.name}`}
                      options={items}
                      renderInput={(params) => (
                        <TextField {...params} label="Name" variant="outlined" size="small" fullWidth />
                      )}
                    />
                  </TableCell>
                  <TableCell style={{ display: 'flex', columnGap: '5px' }}>
                    {item &&
                      ((getItemRate(item) || []).length <= 1 ? (
                        <>
                          <TextField
                            label="Rate"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={rate || null}
                            onChange={(e) => {
                              setRate(e.target.value);
                            }}
                            type="number"
                          />
                          <TextField
                            select
                            label="Duration"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={rateDuration}
                            onChange={(e) => {
                              setRateDuration(e.target.value);
                            }}
                          >
                            {Object.values(RATE_DURATION).map((dur) => (
                              <MenuItem key={dur} value={dur}>
                                {dur}
                              </MenuItem>
                            ))}
                          </TextField>
                        </>
                      ) : (
                        (getItemRate(item) || []).map((rate) => `${rate.value}${rate.label}`).join(' + ')
                      ))}
                  </TableCell>
                  <TableCell>
                    {`${itemRentalPeriod?.hours ? `${itemRentalPeriod?.hours}h/` : ''}${
                      itemRentalPeriod?.days ? `${itemRentalPeriod?.days}d` : ''
                    }${itemRentalPeriod?.months ? `/${itemRentalPeriod?.months}m` : ''}${
                      itemRentalPeriod?.years ? `/${itemRentalPeriod?.years}y` : ''
                    }`}
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="Count"
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="number"
                      value={quantity}
                      onChange={(e) => {
                        setQuantity(e.target.value);
                      }}
                    />
                  </TableCell>
                  <TableCell>{itemTotalRate}</TableCell>
                  <TableCell>
                    <TextField
                      label="Tax"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={taxAmount}
                      type="number"
                      disabled
                      onChange={(e) => {
                        setTaxAmount(e.target.value);
                      }}
                    />
                  </TableCell>
                  <TableCell>{itemTotalAmount}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      disabled={itemCode === '' || itemName === ''}
                      style={{ minWidth: 0, width: 35 }}
                      onClick={addItem}
                    >
                      <LibraryAddIcon />
                    </Button>
                  </TableCell>
                </TableRow>
                {saleItems.length > 0 && (
                  <TableRow key={'total'} className={styles.hilightRow}>
                    <TableCell>Total</TableCell>
                    <TableCell>{saleItems.length} items</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>{saleTotals.totalQuantity}</TableCell>
                    <TableCell>{saleTotals.totalItemAmount}</TableCell>
                    <TableCell>{saleTotals.totalTax}</TableCell>
                    <TableCell>{saleTotals.totalAmount}</TableCell>
                    <TableCell align="center" colSpan={2}>
                      {saleTotals.totalAmount - discount}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} className={styles.submitSection}>
          <Button variant="contained" color="secondary" className={styles.closeBtn} onClick={handleClearPurchase}>
            Cancel
          </Button>
          {saleData?.id && saleData?.status === SALE_STATUS.DRAFT && (
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={() => {
                updateSaleStatus({ status: SALE_STATUS.CONFIRMED });
              }}
            >
              CONFIRM
            </Button>
          )}
          {saleData?.id && saleData?.status === SALE_STATUS.CONFIRMED && (
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={() => {
                updateSaleStatus({ status: SALE_STATUS.RETURNED });
              }}
            >
              RETURNED
            </Button>
          )}
          {saleData?.id && saleData?.status === SALE_STATUS.RETURNED && (
            <Button
              variant="contained"
              style={{ marginRight: 10 }}
              onClick={() => {
                updateSaleStatus({ status: SALE_STATUS.PAID });
              }}
            >
              PAID
            </Button>
          )}
          {saleData?.status !== SALE_STATUS.PAID && (
            <Button
              variant="contained"
              style={{ backgroundColor: '#00a65a' }}
              disabled={startDate === '' || saleItems.length === 0}
              onClick={saleData.id ? handleEditSale : handleAddSale}
            >
              {saleData.id ? 'Update Sale' : 'Create Sale'}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={2} className={styles.activeContainer}>
        <Grid
          container
          xs={12}
          className={styles.changeable}
          style={{ marginTop: 0, alignContent: 'flex-start' }}
          rowSpacing={2}
        >
          <Grid item xs={12} display="flex" justifyContent="center">
            <span className={styles.subHeader}>Active Sales</span>
          </Grid>
          <Grid item container xs={12} className={styles.link} rowSpacing={1}>
            {activeSales && activeSales.length ? (
              activeSales.map((sale) => (
                <>
                  <Grid item xs={12} className={styles.link}>
                    <Link to={`${ROUTES.DASHBOARD}/${sale.id}`}>
                      <span>
                        {`${sale.customer} - ${sale.items
                          .map((item) => {
                            const matchedItem = items.find((i) => i.id === item.itemId);
                            return matchedItem ? matchedItem.name : '';
                          })
                          .join(', ')}`}{' '}
                      </span>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              ))
            ) : (
              <Grid item xs={12} display="flex" justifyContent="center">
                <span style={{ color: '#a0aebd', cursor: 'default' }}>There is no active sales</span>
              </Grid>
            )}
          </Grid>
          <Link to={ROUTES.ACTIVE_ORDERS}>
            <Tooltip title="See more">
              <RemoveRedEyeIcon className={styles.moreIcon} />
            </Tooltip>
          </Link>
        </Grid>
      </Grid>
      {isShowAddCustomerDialog && <NewCustomerDialog toggleAddTab={toggleCreateCustomerDialog} />}
      {customerNotSelected && (
        <WarningDialog
          closeHandler={() => {
            setCustomerNotSelected(false);
          }}
          heading={'Customer Required'}
          message={'A customer is required to create a sale. Please select an existing customer or create a new one.'}
        />
      )}
    </Grid>
  );
}

export default withConsoleBase(Dashboard);

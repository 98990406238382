import BackendService from './BackendService';

async function getMachines() {
  return BackendService.get('/machines/');
}
async function getBillFormat(terminalId) {
  return BackendService.get(`/terminals/${terminalId}/invoice_format/`);
}
async function updateBillFormat(data, terminalId) {
  return BackendService.post(`/terminals/${terminalId}/invoice_format/`, data);
}

const BillFormatService = {
  getMachines,
  getBillFormat,
  updateBillFormat,
};

export default BillFormatService;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, MenuItem, TextField } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CreateIcon from '@material-ui/icons/Create';
import { AlertMessagesContext } from 'react-alert-messages';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import EditItemDialog from '../../popups/EditItemDialog';
import AddItemDialog from '../../popups/AddItemDialog';
import ItemService from '../../../services/ItemService';
import { saveAs } from 'file-saver';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import CsvHelper from '../../../helpers/CsvHelper';
import ArrayHelper from '../../../helpers/ArrayHelper';
import { Paper, Pagination, Grid, TablePagination } from '@mui/material';
import { STATUS } from '../../../const';

function ItemsList() {
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterText, setFilterText] = useState('');
  const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
  const [isShowAddItemDialog, setIsShowAddItemDialog] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [isShowRefreshButton, setIsShowRefreshButton] = useState(false);

  const CSV_COLUMNS = {
    name: 'Name',
    unit: 'Unit',
    is_available: {
      title: 'Available',
      getValue: (item) => (item.is_available ? 'YES' : 'NO'),
    },
    category_name: {
      title: 'Category',
      getValue: (item) => categories.find((category) => category.id === item.categoryId)?.name,
    },
    stock: 'Stock',
    available_stock: 'Available Stock',
    maintenance: 'Maintenance',
    hourly: 'Hourly',
    daily: 'Daily',
    monthly: 'Monthly',
    yearly: 'Yearly',
    one_time: 'One Time',
  };

  const dismissEditDialog = () => {
    setSelectedItemForEdit(null);
  };

  const dismissCreateDialog = () => {
    setIsShowAddItemDialog(false);
  };

  const showCreateDialog = () => {
    setIsShowAddItemDialog(true);
  };

  const getItems = useCallback(
    async ({ _offset = offset, _limit = limit, search = filterText, _filterCategory = filterCategory } = {}) => {
      setIsShowLoading(true);
      try {
        const params = {
          offset: _offset,
          limit: _limit,
          search,
          categoryId: _filterCategory === 'all' ? undefined : _filterCategory,
        };
        const response = await ItemService.getItems(params);
        setItems(response);
        setIsShowRefreshButton(false);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
      setIsShowLoading(false);
    },
    [limit, offset, filterText, filterCategory, postAlertMessage]
  );

  const handleGetExportData = async () => {
    setIsShowLoading(true);
    try {
      const allItems = await ItemService.getItems();
      await handleExportCsv(allItems);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setIsShowLoading(false);
  };

  const handleExportCsv = async (exportItems) => {
    const csv = CsvHelper.getString(exportItems, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `items-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const handleImportCsv = async (csvFile) => {
    setIsShowLoading(true);

    try {
      const csvData = await CsvHelper.parseCsvFile(csvFile);
      const items = csvData.map((row) => {
        if (!row.PLU || row.PLU === '0') {
          throw new Error('PLU must be provided and cannot be zero.');
        } else if (!row.Name || !row.Price || !row.Unit || !row.Category) {
          throw new Error('The fields "name", "unit", "price", and "category" are required and must not be empty.');
        }
        const category = categories.find((c) => c.name === row.Category) || {};
        return {
          plu: Number(row.PLU),
          external_item_id: row.External_Item_ID,
          name: row.Name,
          unit: row.Unit.toLowerCase() === 'kg' ? 'Kg' : 'Pcs',
          price: Number(row.Price),
          ean: row.EAN,
          is_available: row.Available === 'YES',
          sync_enabled: row.Machine_Sync === 'YES',
          stock: Number(row.Stock),
          cost: Number(row.Cost),
          tax: Number(row.Tax),
          cess: Number(row.Cess),
          order: Number(row.Order),
          category: {
            id: category.id,
            name: row.Category,
          },
        };
      });

      const alertId = 'items-batch-update';
      const itemsChunked = ArrayHelper.chunk(items, 40);
      postAlertMessage({
        key: alertId,
        text: `Updating items as ${itemsChunked.length} segments`,
        timeout: -1,
      });
      for (const [index, itemsChunk] of itemsChunked.entries()) {
        await ItemService.updateItems(itemsChunk);
        postAlertMessage({
          key: alertId,
          text: `${index + 1}th chunk uploaded successfully`,
          timeout: -1,
        });
      }

      getItems();

      postAlertMessage({
        key: alertId,
        text: 'Item imported successfully',
        type: 'success',
      });
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed', timeout: 5000 });
    }
    setIsShowLoading(false);
  };

  const handleCategorySearch = (_filterCategory) => {
    setFilterCategory(_filterCategory);
    getItems({ _filterCategory });
  };

  const handleItemsSearch = (value) => {
    if (value !== filterText) {
      setOffset(0);
      setFilterText(value);
      setIsShowRefreshButton(true);
    }
  };

  const handleChangePage = (event, page) => {
    const newPage = page - 1;
    setPage(newPage);
    const _offset = newPage * limit;
    setOffset(_offset);
    getItems({ _offset });
  };

  const handleChangeRowsPerPage = (event) => {
    const _limit = +event.target.value;
    setLimit(_limit);
    getItems({ _limit });
  };

  const headerData = [
    {
      label: 'Name',
      id: 'name',
      type: 'text',
      main: 'true',
    },
    {
      label: 'Image',
      id: 'image',
      type: 'image',
    },
    {
      label: 'Unit',
      id: 'unit',
      type: 'text',
    },
    {
      label: 'Stock',
      id: 'stock',
      type: 'text',
    },
    {
      label: 'Available Stock',
      id: 'available_stock',
      type: 'callback',
      viewRender: (item) => {
        return item.stock - item.stockInMaintenance;
      },
    },
    {
      label: 'Tax',
      id: 'tax',
      type: 'text',
    },
    {
      label: 'Available',
      id: 'status',
      type: 'callback',
      viewRender: (item) => {
        return item.status === STATUS.ACTIVE ? 'Yes' : 'No';
      },
    },
    {
      label: 'Maintenance',
      id: 'stockInMaintenance',
      type: 'text',
    },
    {
      label: 'Hourly',
      id: 'hourly',
      type: 'callback',
      viewRender: (item) => {
        return item.rent.hourly ?? '-';
      },
    },
    {
      label: 'Daily',
      id: 'daily',
      type: 'callback',
      viewRender: (item) => {
        return item.rent.daily ?? '-';
      },
    },
    {
      label: 'Monthly',
      id: 'monthly',
      type: 'callback',
      viewRender: (item) => {
        return item.rent.monthly ?? '-';
      },
    },
    {
      label: 'Yearly',
      id: 'yearly',
      type: 'callback',
      viewRender: (item) => {
        return item.rent.yearly ?? '-';
      },
    },
    {
      label: 'One Time',
      id: 'oneTime',
      type: 'callback',
      viewRender: (item) => {
        return item.rent.oneTime ?? '-';
      },
    },
    {
      label: 'Update',
      id: 'updateItems',
      type: 'button',
      title: 'Edit Item',
      clickHandler: setSelectedItemForEdit,
    },
  ];

  const getRowStyle = (row) => {
    if (row.stock - row.stockInMaintenance < 0) {
      return { backgroundColor: 'rgba(245, 39, 39, 0.2)' };
    } else if (row.stock - row.stockInMaintenance < 5) {
      return { backgroundColor: 'rgba(245, 169, 39, 0.2)' };
    }
    return;
  };

  useEffect(() => {
    const getCategories = async () => {
      const res = await ItemService.getItemCategories();
      setCategories(res);
    };

    getCategories();
  }, []);

  useEffect(() => {
    getItems();
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={isShowLoading} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          {' '}
          Item<span className={styles.menuTitle}>list</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Products</h2>
          </div>
          <div className={styles.filerInputSec}>
            <TextField
              select
              variant="outlined"
              size="small"
              defaultValue="all"
              selected={filterCategory}
              onChange={(e) => {
                handleCategorySearch(e.target.value);
              }}
              className={styles.selectBox}
            >
              <MenuItem value="all">All</MenuItem>
              {categories.map((category) => (
                <MenuItem value={category.id}>{category.name}</MenuItem>
              ))}
            </TextField>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={filterText}
                onChange={(e) => {
                  handleItemsSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search items"
              />
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={getItems}
                disabled={isShowRefreshButton === false}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.actionBtn}
              onClick={showCreateDialog}
            >
              <CreateIcon className={styles.actionBtnIcon} />
              NEW ITEM
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={styles.actionBtn}
              style={{ backgroundColor: '#ff851b' }}
            >
              <input
                accept="text/csv"
                type="file"
                hidden
                id="import"
                onChange={(event) => {
                  const fileField = event.target;
                  const csvFile = fileField.files[0];
                  fileField.value = null;
                  handleImportCsv(csvFile);
                }}
              />
              <ImportExportIcon className={styles.actionBtnIcon} />
              <label for="import">IMPORT CSV</label>
            </Button>
          </div>

          <Button
            variant="contained"
            color="primary"
            size="small"
            className={styles.actionBtn}
            style={{ backgroundColor: '#d81b60' }}
            onClick={handleGetExportData}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            EXPORT TO CSV
          </Button>
        </div>
      </div>

      {items &&
        (items.length ? (
          <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '4px' }}>
            <DataTable columns={headerData} rows={items || items} rowStyle={getRowStyle} />
            <Grid container>
              <Grid item xs={6} display="flex" justifyContent="start">
                <TablePagination
                  rowsPerPageOptions={[100, 200]}
                  component="div"
                  rowsPerPage={limit}
                  page={page}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    '& .MuiTablePagination-actions': {
                      display: 'none',
                    },
                  }}
                  labelDisplayedRows={() => ''}
                />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end" pt={1}>
                <Pagination page={page + 1} color="primary" onChange={handleChangePage} defaultPage={1} />
              </Grid>
            </Grid>
          </Paper>
        ) : (
          isShowLoading === false &&
          !items.length && (
            <>
              <Info
                title={'You have no item to current filter'}
                content={
                  'there are no results that match your current filter configration. Please try a different filter combination to view the desired data. '
                }
              />
            </>
          )
        ))}

      {selectedItemForEdit && (
        <EditItemDialog
          handleClose={dismissEditDialog}
          item={selectedItemForEdit}
          categories={categories}
          onSuccess={getItems}
        />
      )}

      {isShowAddItemDialog && (
        <AddItemDialog handleClose={dismissCreateDialog} categories={categories} onSuccess={getItems} />
      )}
    </div>
  );
}

export default withConsoleBase(ItemsList);
